import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import * as am4core from "@amcharts/amcharts4/core";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import CookieBot from "react-cookiebot";
import { TranslationProvider } from "../Context/Translation";

import {
  REACT_APP_AM_CHARTS_LICENCE,
  REACT_APP_AM_CHARTS_MAPS_LICENCE,
} from "../Constants/ChartConstants";

import { PAGE } from "../Constants/RouteConstants";

const LayoutContainer = React.lazy(() => import("./LayoutContainer"));
const PageContainer = React.lazy(() => import("./PageContainer"));
const PageMissing = React.lazy(() => import("../Components/Debug/PageMissing"));
const SearchContainer = React.lazy(() => import("./SearchContainer"));

am4core.addLicense(REACT_APP_AM_CHARTS_LICENCE);
am4core.addLicense(REACT_APP_AM_CHARTS_MAPS_LICENCE);

am4core.useTheme(am4themesAnimated);

/* eslint-disable react/jsx-props-no-spreading */
const RootContainer = () => {
  return (
    <React.Suspense fallback={<></>}>
      <BrowserRouter>
        <TranslationProvider>
          <CookieBot domainGroupId="a6b9a8fd-b3e7-4d66-9bdd-84f08d48c9f2" />
          <LayoutContainer>
            <section id="main" className="pt-xlarge">
              <Switch>
                <Route exact path="/error" component={PageMissing} />
                <Route
                  exact
                  path="/s"
                  render={(routerProps) => (
                    <SearchContainer
                      {...routerProps}
                      key={`key_${routerProps.location.search || Math.random()}`}
                    />
                  )}
                />
                <Route
                  path={PAGE}
                  render={(routerProps) => (
                    <PageContainer
                      {...routerProps}
                      key={`key_${routerProps.location.pathname || Math.random()}`}
                    />
                  )}
                />
              </Switch>
            </section>
          </LayoutContainer>
        </TranslationProvider>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default RootContainer;
